@import "../../../Asset/Style/Color.scss";

.menuItem {
  color: $color-black;
  cursor: pointer;

  & a:hover {
    text-decoration: none;
  }

  &.disabled {
    color: $color-disabled;
    cursor: default;

    & svg {
      fill: $color-disabled;
    }
  }
}
