@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

$panel-border-color: #dee2e6;

.header {
    width: 100%;
    height: 81px;
    padding: 10px 15px;

    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;

    border: $panel-border-color 0px solid;
    border-bottom-width: 1px;
}

.header_top_row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
}

.header_buttons_left {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
}

.header_buttons_middle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    flex: 1;
    width: 100%;
}

.header_buttons_right {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: flex-end;
    column-gap: 10px;
    row-gap: 10px;
    align-items: center;
}

@include media-breakpoint-down(xs) {
    .header {
        //height: 250px; // height when diff editor button is present
        height: 191px;
    }

    .header_top_row {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .header_buttons_right {
        & > button {
            width: 100%;
        }
    }

    .header_buttons_middle {
        column-gap: 0px;
        row-gap: 0px;
    }

    .header_buttons_left,
    .header_buttons_middle,
    .header_buttons_right {
        width: 100%;

        & > span,
        & > span > a,
        & > button {
            width: 100% !important;
        }
    }
}
