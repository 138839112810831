@import "../../Asset/Style/Color.scss";

.back-button {
    & .back-button-text {
        color: $color-black;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 600;
    }

    & svg {
        width: 16px;
        height: 16px;

        max-width: 16px;
        max-height: 16px;

        fill: $color-black;
        stroke: $color-black;
    }

    &:disabled {
        & .back-button-text {
            color: $color-disabled;
        }

        & svg {
            fill: $color-disabled;
            stroke: $color-disabled;
        }
    }
}
