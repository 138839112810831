@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@import "../../Asset/Style/Color.scss";

$padding-size: 15px;

.back_button {
  margin-right: 10px;
}

.settings_modal_header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.code_editor_page {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 200px;
}

.left_header_group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 14px;
}

.space_title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: $color-black;
}

.action_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 14px;
  // flex: 1;
}

.screen_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: calc(100% - 81px);
  overflow: hidden;
}

.screen_content_wrapper {
  width: 100%;
  max-width: calc(100% - 50px);
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.panel_navigator_item {
  width: 100%;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  height: 47px;

  &.selected {
    &:not(.disabled) {
      background-color: $primary-green;
    }

    &:not(.disabled).not_open {
      background-color: $green-hover-color;
      & path {
        fill: $color-black;
      }
    }

    &.disabled {
      background-color: $disabled-bkg;
    }

    &:first {
      border-bottom: 1px solid $border-color;
    }

    & path {
      fill: white;
    }
  }

  &.disabled {
    &:hover {
      cursor: default;
    }

    & path {
      fill: $color-disabled;
    }
  }
}

.snigelAd {
  padding-top: 5px;
}

.body {
  flex: 1 1;
  width: 100%;
  height: 100%;
  min-height: 100px;
  // max-height: calc(100% - 81px);
  max-height: 100%;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  overflow: hidden;

  &.top_border {
    border: $panel-border-color 0px solid;
    border-top-width: 1px;
  }
}

.code_editor {
  flex: 1 1;
  overflow: hidden;
  height: 100%;
  min-width: 50px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 0px;
  column-gap: 0px;

  &.preview_horizontal {
    flex-direction: column !important;
  }
}

.editor_group {
  background-color: white;

  &.preview_horizontal {
    width: 100%;
    min-height: 77px;
  }
}

.divider_action_btns {
  max-width: 60px;
  min-width: 14px;
  flex: 1;
}

.action_btns {
  padding: 6px 15px;
}

.save_btn {
  min-width: 96.54px;
  margin-left: 50px;
}

.buttons_preview_editor {
  display: none;
  width: 140px;
}

.action_btn_svg_size {
  position: relative;

  & > svg {
    width: 24px;
    height: 24px;

    &.color_green > path:not(:first-child) {
      fill: $primary-green;
    }
  }

  & > .display_log_dot {
    position: absolute;
    fill: $primary-green;
    right: -7px;
    bottom: -8px;
  }

  & > .display_log_dot.not_selected {
    right: -6px;
    bottom: -7px;
  }
}

.panel_content {
  width: 100%;
}

.footer {
  height: 30px;
  overflow: hidden;
  padding-left: $padding-size;
  padding-right: $padding-size;

  border: solid $panel-border-color;
  border-width: 1px 0px 0px 0px;
  background-color: white;
  z-index: 2;

  &.bottom_border {
    border-width: 1px 0px 1px 0px;
  }
}

.case_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;

  width: 100%;
  height: 75px;
  padding: 12px 0px 12px 20px;
  background-color: white;
  border-top: 1px solid $panel-border-color;

  & button:last-child {
    margin-right: 130px;
  }
}

.case_footer_end_group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 5px;
  align-items: center;
  height: 100%;
}

.case_nav_button {
  padding: 0.69532rem 1.8rem;
  border: 1px solid $panel-border-color;
  box-sizing: border-box;
  border-radius: 25px;
  flex-grow: 0;
  width: 151px;
}

.case_nav_button_long {
  width: 250px;
}

.case_btn_light {
  background-color: white;

  &:disabled {
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: $green-hover-color;
  }
}

.case_btn_dark {
  background-color: $color-black;
  color: white;
  border-width: 0px;

  &:disabled {
    background-color: $color-disabled;
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: #333;
  }
}

.diff_group {
  height: 50px;

  &.active > button,
  &.active > button:hover,
  &.active > button:focus,
  &.active > button:active {
    box-shadow: 0px 0px 16px 3px $panel-border-color inset;
  }
}

.code_editor_hint_key {
  font-size: 10px;
  margin: 0.1em 0.1em;
  padding: 0.1em 0.6em;
  font-family: sans-serif;
  background-color: white;
  border: 1px solid gray;
  border-radius: 3px;
}

.code_editor_button {
  font-size: 14px;

  border-radius: 25px;
  border: $panel-border-color solid;
  border-width: 1px;

  padding: 2px $padding-size 2px $padding-size;

  background-color: white;
  color: $color-black;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;

  &:disabled {
    color: $color-disabled;
  }

  &:hover:not(:disabled) {
    border-color: rgba(40, 42, 53, 0.5) !important;
  }
}

.preview_error {
  padding: 10px 10px 0px 10px;
}

.type_error {
  background-color: red;
}

/* .loader {
  max-width: 0%;
  width: 0px;
  max-height: 100%;
  height: 100%;
  flex: 1;

  &.loading {
    max-width: 100%;
    width: 100%;
  }
} */

.loading_timer_text {
  margin-top: 20px;
  font-weight: 400;
}

.bottom_panel {
  width: 100%;
  max-height: 0%;
  height: 25%;

  transition: max-height 0.7s ease;

  &.display_bottom_panel {
    max-height: calc(100% - 220px);
  }
}

.left_panel:not(.display) {
  display: none;
}

.right_top_header {
  flex-wrap: nowrap;
}

.upgrade_kai_token {
  color: $color-black;
  text-decoration: underline;

  &:hover {
    color: black;
  }
}

:global {
  .code-editor-modal-body-wrp {
    .quota-info-bar-container {
      top: 0px !important;
      display: none !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .navigator {
    display: none !important;
  }
  .screen_content_wrapper {
    max-width: 100%;
  }

  .footer {
    display: none;
  }

  .tech_icon {
    flex: 1;
  }

  .left_header_group {
    flex-wrap: wrap;
    width: 100%;
  }

  .action_wrapper {
    flex-direction: row-reverse;
    width: 100%;
    flex: 1;
    gap: 115px;
  }

  .action_wrapper span,
  .button_group {
    display: none !important;
  }

  .save_btn {
    margin-left: 10px;
  }

  .action_btns.buttons_preview_editor {
    display: flex;
  }
}

@include media-breakpoint-down(xs) {
  .tech_icon {
    max-width: 100%;
  }

  .left_header_group {
    width: 100%;
    flex-wrap: wrap;
  }

  .save_btn {
    margin-left: 0px;
  }

  .action_wrapper {
    flex-direction: column;
    gap: 10px;
    flex: 1 0 100%;

    & span,
    & button {
      width: 100%;
    }
  }

  .diff_group {
    width: 100%;

    & > button {
      width: 100%;
    }
  }
}
