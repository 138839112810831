@import "../../Asset/Style/Color.scss";

.wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: $color-black;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 10px;

    position: relative;

    &.showPadlock > svg:first-child path,
    &.showPadlock > svg:first-child:not(.react_icon_variant) rect,
    &.showPadlock > svg:first-child {
        fill: $color-disabled;
    }
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
}

.o_h {
    overflow: hidden;
}

.padlock {
    width: 100%;
    height: 100%;

    &.displayed_react path {
        fill: black;
    }
}

.display_svg {
    position: absolute;
    left: 0px;
    top: 0px;
}

:global {
    .space-icon-window {
        &.size-x-sm > svg {
            width: 14px;
            height: 14px;
        }

        &.size-sm > svg {
            width: 18px;
            height: 18px;
        }
        &.size-md > svg {
            width: 40px;
            height: 40px;
        }
        &.size-lg > svg {
            width: 56px;
            height: 56px;
        }

        &.dynamic_disabled_dot > svg:first-child path,
        &.dynamic_disabled_dot > svg:first-child:not(.react-icon-variant) rect,
        &.dynamic_disabled_dot > svg:first-child,
        &.DELETED > svg:first-child path,
        &.DELETED > svg:first-child:not(.react-icon-variant) rect,
        &.DELETED > svg:first-child {
            fill: rgba(40, 42, 53, 0.4) !important;
        }

        .dot {
            fill: #00fe24;
            stroke: $primary-green;
        }

        &.PRIVATE {
            .dot {
                fill: #ffb100;
                stroke: #845c00;
            }
        }

        &.DELETED {
            .dot {
                fill: $color-disabled;
                stroke: grey;
            }
        }

        &.dynamic_disabled_dot,
        &.INACTIVE,
        &.BANNED {
            .dot {
                fill: $color-error;
                stroke: #820000;
            }
        }
    }
}
