@import "../../../Asset/Style/Color.scss";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 6px;
  justify-content: center;
  align-items: center;

  color: $color-black;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  cursor: pointer;

  & > svg:not(.stars_icon) {
    width: 24px;
    height: 24px;
  }

  &:not(.disabled).selected {
    color: $primary-green;

    & > svg:not(.stars_icon),
    & > svg:not(.stars_icon) > path {
      fill: $primary-green;
    }
  }

  &.disabled {
    cursor: default;
    color: $color-disabled !important;

    & > svg,
    & > svg > path {
      fill: $color-disabled !important;
    }
  }

  & > svg:not(.stars_icon),
  & > svg:not(.stars_icon) > path {
    fill: $color-black;
  }
}

.stars_icon {
  position: absolute;
  top: -6px;
  right: 8px;
  scale: 1.4;
}
