.loading-box {
    position: relative;
    display: flex;
    // align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;

    > .-inner-wrp {
        // centering
        position: relative;
        display: flex;
        // align-items: center;
        text-align: center;
        flex-direction: row;
        align-items: center;
        height: 100%;

        > .-inner-wrp {
            // centering
            position: relative;
            display: flex;
            // align-items: center;
            text-align: center;
            flex-direction: column;
            width: 100%;

            > .-title {
                font-weight: bold;
                font-size: 29px;
                color: #282a35;
                margin: 0;
            }
        }
    }
}

@keyframes loading-box-spinner-animation {
    100% {
        transform: rotate(360deg);
    }
}
