@import "../../Asset/Style/Color.scss";

.wrapper {
  background-color: white;
  width: 100vw;
  height: 90px;
  padding: 10px 15px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid $border-color;
  // display: flex; // The component using this component needs to set this value
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}
