@import "../../../Asset/Style/Color.scss";

.wrapper {
  display: flex;
}

.item {
  color: $color-black;
  font-weight: 600;

  &.disabled {
    color: $color-disabled;
  }

  &.selected {
    text-decoration: underline;
  }
}

.menuChild {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  position: relative;

  & svg.display_log_dot {
    position: absolute;
    fill: $primary-green;
    width: 18px;
    height: 18px;
    left: 50px;
    bottom: 0px;

    &.not_selected {
      left: 29px;
    }
  }

  & .checkIcon {
    fill: $primary-green;
    width: 16px;
    height: 16px;
  }

  & > svg.color_green > path:not(:first-child) {
    fill: $primary-green;
  }
}
