@import "../../Asset/Style/Color.scss";

.tooltip > div:not(:first-child) {
    background-color: $color-black;
    color: white;
}

:global {
    .bs-popover-bottom > .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
        border-bottom-color: $color-black;
    }

    .bs-popover-right > .arrow::after,
    .bs-popover-auto[x-placement^="right"] > .arrow::after {
        border-right-color: $color-black;
    }

    .bs-popover-left > .arrow::after,
    .bs-popover-auto[x-placement^="left"] > .arrow::after {
        border-left-color: $color-black;
    }

    .bs-popover-top > .arrow::after,
    .bs-popover-auto[x-placement^="top"] > .arrow::after {
        border-top-color: $color-black;
    }

    .w3s-tooltip-wrapper > *:disabled {
        pointer-events: none;
    }
}
