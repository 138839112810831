@mixin setBorderRadius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusTopCorners($radius: 5px) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusBottomCorners($radius: 5px) {
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusLeftCorners($radius: 5px) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBorderRadiusRightCorners($radius: 5px) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin setBoxShadow($args) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
  //  .ie6 &, .ie7 &, .ie8 & {
  //    zoom: 1;
  //    filter: "progid:DXImageTransform.Microsoft.DropShadow(OffX=#{$offx}, OffY=#{$offy}, Color=#{$color})";
  //  }
}

@mixin clearFloats() {
  // Clearfix
  // --------
  // For clearing floats like a boss h5bp.com/q
  *zoom: 1;
  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

@mixin forceFullSizeRelElmImp() {
  position: relative !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

@mixin forceFullSizeAbsElmImp() {
  position: absolute !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
}

@mixin fadeIn($speed: 0.7s) {
  opacity: 1;
  animation: fadeInKeyframe $speed;
}

@mixin fadeOut($speed: 0.7s) {
  animation: fadeOutKeyframe $speed;
}

@keyframes fadeInKeyframe {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutKeyframe {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
