@import "../../Asset/Style/Color.scss";

.notification-wrapper {
    position: fixed;
    top: 130px;
    right: 7px;
    width: 350px;
    z-index: 999999;

    & > .notification-close-all {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        padding-right: 5px;
        color: $color-black;

        & > div {
            background-color: rgba(0, 0, 0, 0.175);
            border-radius: 20px;
            padding: 2px 15px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.45);
                color: white;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    > .toast {
        background: #ffffff;
        border: 1px solid #e3e3e3;
        box-sizing: border-box;
        box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.175);
        border-radius: 16px;
        padding: 18px 21px;

        > .toast-header {
            display: flex;
            align-items: center;
            padding: 0.25rem 0;
            color: #282a35;
            font-family: Source Sans Pro;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            background: none;
            border: 0px;
            border-top-left-radius: calc(0.25rem - 1px);
            border-top-right-radius: calc(0.25rem - 1px);

            > .-icon {
                margin-right: 8px;
            }
            > .-text {
                flex-grow: 1;
            }
            .-spinner {
                &::after,
                &::before {
                    content: none;
                }
            }
        }
        .toast-body {
            overflow: hidden;
            font-family: Source Sans Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: #282a35;
            padding: 0;

            .single {
                padding: 13px 0 10px;
                position: relative;
                .filename {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 306px;
                    overflow: hidden;
                    display: block;
                    padding-right: 26px;
                }
                .upload-success-icon {
                    position: absolute;
                    top: 9px;
                    right: 6px;
                }
            }

            .w3s-notif-upload-success-text {
                max-width: 280px;
            }

            .progress {
                height: 8px;
                background-color: white;
                margin: 10px 0 0 0;
                &:not(.progress-success) {
                    border: solid 1px black;
                }

                .progress-bar.bg-info {
                    background-color: black !important;
                }

                .progress-bar.bg-sucess {
                    background-color: #04aa6d !important;
                }
            }

            .progress.progress-sucess {
                border: solid 1px #04aa6d !important;
            }
        }
    }
}
