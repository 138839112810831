@import "~bootstrap/scss/bootstrap";
@import "../../Asset/Style/Util/MixedUtil.scss";
@import "../../Asset/Style/Vars.scss";
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700);

$textBlack: rgb(40, 42, 53);
$titleColor: rgb(21, 37, 54);

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: $textBlack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

nav {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.row {
  padding-right: 43px;
  padding-left: 35px;
}

h1 {
  font-weight: 600;
  font-size: 36px;
}

th {
  font-size: 16px;
  font-weight: 600;
  color: $titleColor;
}

.container-fluid {
  min-height: calc(100vh - 141px);
  margin-bottom: -59px; // Create some space for terms
  padding-bottom: 59px;

  @media (max-width: $mobile-file-view-break) {
    margin-bottom: 0px;
    padding-bottom: 0px;
    min-height: initial;
    max-height: calc(100vh - 88px);
    height: 100%;

    &.display-banner {
      max-height: calc(100vh - 141px);
    }
  }
}

.header-row {
  padding-top: 30px;
  padding-bottom: 24px;
}

.header-nav-bar {
  line-height: normal;

  svg {
    overflow: visible !important;
  }

  #navigation {
    svg {
      height: 19px;
    }
  }

  @media (max-width: 800px) {
    #navigation {
      svg {
        height: 22px;
        margin-top: 1px;
      }
    }
  }
}

.suspense-spinner {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1500;

  display: flex;
  align-items: center;
  justify-content: center;
}

.close-adhesive {
  display: none;
}

.sn_ad_label {
  position: relative;
  top: -8px;
  text-align: left !important;
  font-family: "Source Sans Pro" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 9px !important;
  line-height: 16px !important;
}

.btn-std-flat {
  background-color: #04aa6d;
  color: white;
  height: 50px;
  border-radius: 25px;
  padding-left: 18px;
  padding-right: 18px;
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;

  &:hover,
  &.hover {
    color: white;
    background-color: darken(#04aa6d, 5%);
  }

  &:focus,
  &.focus {
    border-color: #04aa6d;
    @include setBoxShadow(0 0 0 0.2rem rgba(4, 170, 109, 0.5));
  }
}

.logout_btn {
  border: none;
  background-color: transparent;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #282a35;
  &:hover {
    color: #059963;
  }
}

.btn-cta,
.btn-cta.btn-primary {
  font-family: Source Sans Pro, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  white-space: nowrap;
  background-color: #04a96d;
  color: #fff;

  height: 50px;
  padding: 15px 40px 14px;
  border-radius: 25px;
  border: 0;

  &:hover {
    background-color: #059963;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      background-color: #03724a;
    }
  }

  &:focus {
    background-color: #059963;
    box-shadow: 0 0 0 0.2rem rgba(5, 152, 98, 0.5);
  }

  &:disabled,
  &.disabled {
    background-color: #e2e8ea;
    color: #798588;
  }

  &:active,
  &.active {
    background-color: #04a96d !important;
    color: #fff !important;
  }

  &.link {
    background-color: #403196;
    color: #fff;

    &:hover {
      background-color: #392c87;
    }

    // &:not(:disabled):not(.disabled) {
    //     &:active,
    //     &.active {
    //         background-color: #2c2268;
    //     }
    // }

    &:focus {
      background-color: #392c87;
      box-shadow: 0 0 0 0.2rem rgba(57, 44, 135, 0.5);
    }

    &:active,
    &.active {
      background-color: #403196 !important;
      color: #fff !important;
    }
  }
}

.btn-cta.secondary {
  background-color: #fff;
  color: #282a35;
  border: 2px solid #04aa6d;

  &:hover {
    border-color: #059963;
    background-color: #fff;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      border-color: #03724a;
      background-color: #fff;
    }
  }

  &:focus {
    border-color: #059963;
    box-shadow: 0 0 0 0.2rem rgba(5, 152, 98, 0.5);
  }

  &:disabled,
  &.disabled {
    border-color: #e2e8ea;
    color: #798588;
  }

  &:active,
  &.active {
    border-color: #04a96d;
    background-color: #fff !important;
    color: #282e35 !important;
  }

  &.link {
    background-color: #403196;
    color: #fff;

    &:hover {
      background-color: #392c87;
    }

    // &:not(:disabled):not(.disabled) {
    //     &:active,
    //     &.active {
    //         background-color: #2c2268;
    //     }
    // }

    &:focus {
      background-color: #392c87;
      box-shadow: 0 0 0 0.2rem rgba(57, 44, 135, 0.5);
    }

    &:active,
    &.active {
      background-color: #403196 !important;
      color: #fff !important;
    }
  }
}

.action-round-btns-wrp {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-content: flex-end;
}

.btn {
  &.btn-sm-round {
    @include setBorderRadius(50%);
    margin-left: 4px;
    margin-right: 4px;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    color: #282a35;
    border-color: #dee2e6;

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: #212529 !important;
      border-color: #798488 !important;
      background-color: #e7e9eb !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active {
      // box-shadow: inset 0 0 0 0.2rem rgba(52, 58, 64, 0.2) !important;
      box-shadow: inset 0 0 0.2rem 0.1rem rgba(52, 58, 64, 0.2) !important;
    }

    &.dropdown-toggle {
      &:after {
        display: none !important;
      }
    }
  }

  &.btn-md-round {
    @include setBorderRadius(50%);
    color: #282a35;
    border: 1px solid #dee2e6;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;

    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: #212529 !important;
      border-color: #798488 !important;
      background-color: #e7e9eb !important;
    }

    &:focus,
    &.focus,
    &:active,
    &.active {
      box-shadow: inset 0 0 0.2rem 0.1rem rgba(52, 58, 64, 0.2) !important;
    }

    &.loading {
      animation: pulse 1s infinite;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
    }

    @keyframes pulse {
      0% {
        background-color: #fff;
      }
      100% {
        background-color: #dee2e6;
      }
    }

    svg {
      max-height: 18px;
      max-width: 18px;
    }

    &.btn-color-green {
      color: #fff;
      background-color: #059862;
      border-color: transparent;

      &:hover,
      &.hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        color: #fff !important;
        border-color: #fff !important;
        background-color: #03744b !important;
      }

      &:focus,
      &.focus,
      &:active,
      &.active {
        box-shadow: inset 0 0 0.2rem 0.1rem rgba(52, 58, 64, 0.8) !important;
      }
    }
  }
}

.dropdown {
  .dropdown-menu {
    padding: 0.15rem 0;
  }

  .dropdown-item {
    padding: 0.5rem 1.5rem;

    &:hover,
    &.hover {
      background-color: rgba(64, 87, 109, 0.07);
    }

    &:focus,
    &.focus,
    &:active,
    &.active {
      color: #282a35 !important;
      box-shadow: inset 0 0 0.3rem 0.1rem rgba(52, 58, 64, 0.1) !important;
      background-color: rgba(64, 87, 109, 0.07) !important;
    }

    &.red-text {
      color: #d9212c;

      &:focus,
      &.focus,
      &:active,
      &.active {
        color: #d9212c !important;
      }
    }
  }

  .dropdown-divider {
    margin: 0.15rem 0;
  }
}

.btn-link {
  &.btn-inline {
    margin: 0;
    padding: 0;
  }
}

.rounded-table-wrapper {
  border-radius: 16px;
  border: 1px solid #dee2e6;
  overflow: auto;

  &.empty {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.rounded-table {
  margin-bottom: 0;
  thead {
    border-top: 0px;
  }

  $cellPadding: 18px 25px 16px 25px;

  thead th,
  thead td {
    border-top: 0;
    background-color: #f8f9fa;
    padding: $cellPadding;
  }

  td {
    padding: $cellPadding;
  }

  th,
  td {
    vertical-align: middle;
  }
}

.table-row-cursor {
  cursor: pointer;
}

.table-hover tbody tr {
  &:hover,
  &.hover {
    background-color: #f8f9fa;
  }
}

.modal {
  .modal-header {
    > .modal-title {
      display: flex;
    }

    button.close {
      font-size: 2rem;
      font-weight: 500;
    }

    > .-action-btns-wrp {
      display: flex;
    }
  }
}

.modal_button {
  & a:hover {
    text-decoration: none;
  }
}

.mr-12 {
  margin-right: 12px;
}

.mr-13 {
  margin-right: 13px;
}

.mr-5 {
  margin-right: 5px;
}

.pos-rel {
  position: relative;
}

.b--5 {
  bottom: -5px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-like {
  cursor: pointer;

  &:focus,
  &.focus,
  &:active,
  &.active {
    // box-shadow: inset 0 0 0 0.2rem rgba(52, 58, 64, 0.2) !important;
    box-shadow: inset 0 0 0.2rem 0.1rem rgba(52, 58, 64, 0.2) !important;
  }
}

#layers {
  .-menu-item.btn {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .popover-menu-wrp {
    z-index: 1005;
  }
}

.footer-terms {
  &.-display-in-mobile-usage {
    display: none;
  }

  @media (max-width: $mobile-file-view-break) {
    display: none;

    &.-display-in-mobile-usage {
      display: block;
    }
  }
}

.popover-trigger-wrapper-round {
  border-radius: 50%;
}

.mobile-usage-panel-wrapper {
  position: fixed;
  bottom: 0px;
  left: 0px;

  display: none !important;

  @media (max-width: $mobile-file-view-break) {
    display: flex !important;
  }
}

@media (max-width: 450px) {
  .row {
    padding-right: 0px;
    padding-left: 0px;
  }

  .header-row {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .-mobile {
    width: 100%;
    text-align: right;
    margin-right: 0px !important;
    &-mr {
      margin-right: 12px !important;
    }
    a,
    button {
      display: inline-block;
      width: 100%;
      margin-top: 5px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .btn.btn-md-round {
    width: 44px;
    height: 44px;
  }

  .fixed_table {
    table-layout: fixed;
    width: 100%;

    $cellPadding: 8px 10px 8px 10px;
    td,
    th {
      padding: $cellPadding;
    }
  }
}
