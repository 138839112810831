$color_main: #00aa72;
$color_indy_overlay: rgba(197, 223, 207, 0.6);

$primary-green: #04aa6d;
$hover-green: #059862;
$green-hover-color: #d9eee1;

$color_grey: #e7e9eb;

$color-disabled: #798488;
$disabled-bkg: #e2e8ea;
$disabled-bkg-dark: #adadad;
$panel-border-color: #dee2e6;
$color-black: #282a35;
$hover-white: #ffffff;
$border-color: #dee2e6;
$color-error: #d9212c;

$color-annotation: #9763f6;
