@import "../../Asset/Style/Util/MixedUtil.scss";

.popover-menu-wrp {
    background-color: #fff;
    padding: 0.15rem 0;
    border: 1px solid #e7e9eb;
    @include setBorderRadius(0.25rem);
    box-shadow: 0 0 5px 1px rgba(52, 58, 64, 0.1);

    .-menu-item {
        display: block;
        width: 100%;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        padding: 10px 20px;
        font-size: 14px;
        @include setBorderRadius(0);
        transition: none;

        &:hover,
        &.hover {
            background-color: rgba(64, 87, 109, 0.07);
        }

        &:focus,
        &.focus,
        &:active,
        &.active {
            color: #282a35 !important;
            box-shadow: inset 0 0 0.3rem 0.1rem rgba(52, 58, 64, 0.1) !important;
            background-color: rgba(64, 87, 109, 0.07) !important;
        }

        &.-red-text {
            color: #d9212c;

            &:focus,
            &.focus,
            &:active,
            &.active {
                color: #d9212c !important;
            }
        }
    }

    .-divider {
        margin: 0.15rem 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e3e3e3;
    }
}
