@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.container_fluid_override {
    padding: 0px;
    height: 100%;
    width: 100%;
    min-height: 100px;
    overflow: hidden;
}

.code_editor_page {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    overflow: hidden;
    min-width: 200px;
}

@include media-breakpoint-down(xs) {
    .top_header {
        align-items: flex-start;
    }

    .top_header span,
    .top_header span > a {
        width: auto !important;
    }
}
